html {
  background: #fff url('../static/images/bg_sfdc.jpg') no-repeat 0px 1px;
  // background-size: cover;
}

.nav-bar__item {
  list-style: none;
}

.nav-bar__link {
  position: absolute;
  top: 0;
  left: 7.5%;
  display: block;
  width: 40%;
  height: 5%;
  opacity: 0;
  border: 1px solid green;
}