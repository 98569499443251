html {
  background: #fff url("bg_sfdc.838a2f50.jpg") 0 1px no-repeat;
}

.nav-bar__item {
  list-style: none;
}

.nav-bar__link {
  width: 40%;
  height: 5%;
  opacity: 0;
  border: 1px solid green;
  display: block;
  position: absolute;
  top: 0;
  left: 7.5%;
}

/*# sourceMappingURL=index.5c92fe46.css.map */
